var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "quiz-activity-conf-container"
  }, [_c("section", {
    staticClass: "search-wrapper"
  }, [_c("a-form-model", {
    attrs: {
      layout: "inline",
      model: _vm.queryParams
    },
    on: {
      submit: _vm.handleSubmit
    },
    nativeOn: {
      submit: function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c("a-form-model-item", [_c("a-input", {
    attrs: {
      placeholder: "请输入题干"
    },
    model: {
      value: _vm.queryParams.question,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "question", $$v);
      },
      expression: "queryParams.question"
    }
  })], 1), _c("a-form-model-item", [_c("a-select", {
    attrs: {
      allowClear: "",
      placeholder: "请选择状态"
    },
    model: {
      value: _vm.queryParams.validstatus,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "validstatus", $$v);
      },
      expression: "queryParams.validstatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 未生效 ")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 已生效 ")])], 1)], 1), _c("a-form-model-item", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v("查询")]), _c("a-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    on: {
      click: _vm.handleReset
    }
  }, [_vm._v("重置")])], 1)], 1)], 1), _c("section", {
    staticClass: "question-bank-main"
  }, [_c("div", {
    staticClass: "button-action-wrapper"
  }, [_c("a-button", {
    on: {
      click: _vm.handleDownload
    }
  }, [_c("a-icon", {
    attrs: {
      type: "download"
    }
  }), _vm._v("下载模板")], 1), _c("a-upload", {
    attrs: {
      accept: ".xlsx",
      disabled: _vm.uploading,
      showUploadList: false,
      customRequest: _vm.uploadQuizTemp
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "primary",
      loading: _vm.uploading
    }
  }, [_c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.uploading,
      expression: "!uploading"
    }],
    attrs: {
      type: "upload"
    }
  }), _vm._v(_vm._s(_vm.uploading ? "上传中..." : "批量导入"))], 1)], 1), _c("a-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("创建")])], 1), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.dataSource,
      pagination: false,
      rowKey: "id",
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "source",
      fn: function fn(source) {
        return [source == 0 ? _c("span", [_vm._v("系统创建")]) : _vm._e(), source == 1 ? _c("span", [_vm._v("用户贡献")]) : _vm._e()];
      }
    }, {
      key: "validstatus",
      fn: function fn(validstatus) {
        return [validstatus == 0 ? _c("span", [_vm._v("未生效")]) : _vm._e(), validstatus == 1 ? _c("span", [_vm._v("已生效")]) : _vm._e()];
      }
    }, {
      key: "forbiddencard",
      fn: function fn(forbiddencard) {
        return [forbiddencard ? _c("span", [_vm._v("是")]) : _c("span", [_vm._v("否")])];
      }
    }, {
      key: "action",
      fn: function fn(item) {
        return [_c("div", {
          staticClass: "table-actions"
        }, [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleEdit(item);
            }
          }
        }, [_vm._v("编辑")]), _c("a-button", {
          staticStyle: {
            color: "#ff4d4f"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleDel(item);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.queryParams.page,
      size: _vm.queryParams.size,
      onPageSizeChange: _vm.onPageChange,
      onShowSizeChange: _vm.onPageChange
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.queryParams, "page", $event);
      },
      "update:size": function updateSize($event) {
        return _vm.$set(_vm.queryParams, "size", $event);
      }
    }
  })], 1), _c("CreateQuizForm", {
    attrs: {
      visible: _vm.visible,
      value: _vm.formData,
      title: _vm.ismodify ? "修改" : "创建",
      loading: _vm.confirmLoading,
      onConfirm: _vm.onSubmit,
      onCancel: _vm.resetForm
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };